import './Description.css'
import React from 'react'

const Description = () => {
  return (
    <div className='about-container'>
        <h1>About Us </h1>
        <p> </p>
        <h1>Our Achievement </h1>
        <p> </p>
        <h1>Our Vision</h1>
        <p> </p>
    </div>
  )
}

export default Description